import React from 'react'

import ContactSection from '../components/ContactSection';

export default function Contact() {
    return (
        <div>
            <ContactSection />
        </div>
    );
}
